<template>
    <v-container class="text-center">
        <div class="text-h4 font-weight-bold primary--text text--lighten-1">Crear portabildad</div>
        <div class="text-h6">Numero a portar</div>
        <v-text-field label="Ingresa los 10 digitos del numero"></v-text-field>
        <v-btn class="secondary my-3" dark block>Continuar</v-btn>
    </v-container>
</template>
<script>
export default {
    data(){
        return{
        }
    },
    created(){
      this.$emit('muestra', false)
    }
}
</script>